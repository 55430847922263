(function () {
  'use strict';

  class Messages {
    constructor(MessagesRest, $cookies, $rootScope) {
      this.MessagesRest = MessagesRest;
      this.$cookies = $cookies;
      this.$rootScope = $rootScope;
    }

    messagesIndex(messages = {}, callback = angular.noop) {
      return this.MessagesRest.messages().get(messages,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    messagesCreate(messages = {}, callback = angular.noop) {
      return this.MessagesRest.messages().save(messages,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    thread(thread = {}, callback = angular.noop) {
      return this.MessagesRest.thread().get(thread,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    messageEdit(message = {}, callback = angular.noop) {
      return this.MessagesRest.message().update(message,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    messageDelete(message = {}, callback = angular.noop) {
      return this.MessagesRest.message().remove(message,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }
  }

  /**
   * @ngdoc service
   * @name components.service:Messages
   *
   * @description
   *
   */
  angular
    .module('components')
    .service('Messages', Messages);
}());
